import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { getAllGoalDomains } from "../../api/goalDomainApi";
import { getAllGoalActivities } from "../../api/goalActivityApi";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { ProgressBar } from "primereact/progressbar";
import { Slider } from "primereact/slider";
import { deleteGoalDetail, restoreGoalDetail } from "../../api/goalApi";
import { toast } from "react-toastify";

const SingleGoal = ({
  childId,
  goal,
  setGoal,
  goalDetails,
  setGoalDetails,
  handleSaveGoal,
  onHide,
  visible,
  services,
  loading,
  setLoading,
  editMode,
}) => {
  const [goalDomains, setGoalDomains] = useState([]);
  const [selectedGoalDomainId, setSelectedGoalDomainId] = useState(
    null
    // editMode ? goal?.goalDomainId : null
  );

  useEffect(() => {
    if (editMode) {
      setSelectedGoalDomainId(goal?.goalDomainId);
    }
  }, [editMode, goal]);
  const [goalActivities, setGoalActivities] = useState([]);

  useEffect(() => {
    const fetchGoalDomains = async () => {
      setLoading(true);
      try {
        const goalDomains = await getAllGoalDomains();
        setGoalDomains(goalDomains);
      } catch {
        console.log("Error fetching goal domains");
      } finally {
        setLoading(false);
      }
    };
    const fetchGoalActivities = async () => {
      setLoading(true);
      try {
        const goalActivities = await getAllGoalActivities();
        setGoalActivities(goalActivities);
      } catch {
        console.log("Error fetching goal activities");
      } finally {
        setLoading(false);
      }
    };
    fetchGoalDomains();
    fetchGoalActivities();
    console.log(selectedGoalDomainId);
  }, []);
  const addGoalDetailRecord = () => {
    setGoalDetails([
      ...goalDetails,
      {
        activityDate: null,
        goalActivityId: null,
        goalProgress: 0,
        deletedAt: null,
      },
    ]);
  };
  const removeGoalDetailRow = async (id) => {
    if (goalDetails.length >= 1) {
      await deleteGoalDetail(id)
        .then((res) => {
          console.log(res);
          toast.warn("Goal Detail Removed Successfully", { autoClose: 1000 });
          setGoalDetails((prev) =>
            prev.map((goalDetail) =>
              goalDetail?.goalDetailId === id
                ? { ...goalDetail, deletedAt: new Date() }
                : goalDetail
            )
          );
        })
        .catch((err) => {
          toast.error("Error Removing Goal Detail", { autoClose: 1000 });
          console.error(err);
        });
    }
  };
  const restoreGoalDetailRow = async (id) => {
    await restoreGoalDetail(id)
      .then((res) => {
        console.log(res);
        toast.warn("Goal Detail Restored Successfully", { autoClose: 1000 });
        setGoalDetails((prev) =>
          prev.map((goalDetail) =>
            goalDetail?.goalDetailId === id
              ? { ...goalDetail, deletedAt: null }
              : goalDetail
          )
        );
      })
      .catch((err) => {
        toast.error("Error Restoring Goal Detail", { autoClose: 1000 });
        console.error(err);
      });
  };
  // if (goalDetails.length > 1) {
  //   const updatedGoalDetails = [...goalDetails];
  //   updatedGoalDetails.splice(index, 1);
  //   setGoalDetails(updatedGoalDetails);
  // }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={editMode ? "Edit Goal" : "Add New Goal"}
      headerStyle={{ textAlign: "center" }}
      style={{
        width: "600px",
        maxWidth: "90%",
      }}
    >
      <div className="p-col-12 p-shadow-2 p-p-3 p-mb-3 mt-2 ">
        <div className="grid">
          <div className="col-6">
            <label>Title</label>
            <InputText
              value={goal.name}
              onChange={(e) =>
                setGoal((prev) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              placeholder="Write Title"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>{" "}
          <div className="col-6">
            <label>Service</label>
            <br />
            <Dropdown
              value={goal.serviceId}
              onChange={(e) =>
                setGoal((prev) => ({
                  ...prev,
                  serviceId: e.target.value,
                }))
              }
              options={services}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Service"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>
          <div className="col-6">
            <label>Domain</label>
            <br />
            <Dropdown
              value={goal?.goalDomainId}
              onChange={(e) => {
                setSelectedGoalDomainId(e.target.value);
                setGoal((prev) => ({
                  ...prev,
                  goalDomainId: e.target.value,
                }));
              }}
              options={goalDomains}
              optionLabel="name"
              optionValue="id"
              placeholder="Select Goal Domain"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>{" "}
          <div className="col-6">
            <label>Start Date</label>
            <br />
            <Calendar
              value={goal.startDate ? new Date(goal.startDate) : null}
              onChange={(e) => {
                setGoal((prev) => ({
                  ...prev,
                  startDate: e.value,
                }));
              }}
              placeholder="Select Date"
              className="p-mb-2 mb-1 mt-1 full-width"
            />
          </div>
          {editMode && (
            <div className="col-6">
              <label>Mastering Date</label>
              <br />
              <Calendar
                value={goal.masteringDate ? new Date(goal.masteringDate) : null}
                onChange={(e) => {
                  setGoal((prev) => ({
                    ...prev,
                    masteringDate: e.value,
                  }));
                }}
                placeholder="Select Date"
                className="p-mb-2 mb-1 mt-1 full-width"
              />
            </div>
          )}
          <div className="col-12">
            <label>Description</label>
            <InputTextarea
              value={goal.description}
              onChange={(e) =>
                setGoal((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              placeholder="Write Description"
              className="p-mb-2 mb-1 mt-1 full-width "
              rows={3}
            />
          </div>
          {editMode && (
            <div className="col-12">
              <label>Goal Progress</label>
              <ProgressBar
                value={goal.goalProgress}
                style={{
                  height: "18px",
                  borderRadius: "0px",
                  color: "#655BC6",
                }} // Rounded corners to match slider style
                className="full-width border-0 mt-1"
              />

              <Slider
                value={goal.goalProgress}
                onChange={(e) =>
                  setGoal((prev) => ({
                    ...prev,
                    goalProgress: e.value,
                  }))
                }
                style={{
                  height: "5px",
                  borderRadius: "0px",
                  color: "#655BC6",
                }} // Rounded corners to match slider style
                className=" m-0  full-width  border-0 "
              />
            </div>
          )}
          {goalDetails.map((goalDetail, index) => (
            <>
              <div className="col-6">
                <label>Activity {goalDetail.goalDetailId}</label>
                <br />
                <Dropdown
                  value={goalDetail?.goalActivityId}
                  onChange={(e) =>
                    setGoalDetails((prev) =>
                      prev.map((detail, i) =>
                        i === index
                          ? { ...detail, goalActivityId: e.value }
                          : detail
                      )
                    )
                  }
                  options={goalActivities.filter(
                    (goalActivity) =>
                      goalActivity.goalDomainId === selectedGoalDomainId
                  )}
                  disabled={
                    !selectedGoalDomainId || goalDetail.deletedAt !== null
                  }
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Select Goal Activity"
                  className="p-mb-2 mb-1 mt-1 full-width"
                />
              </div>
              <div className="col-6">
                <label>Activity Date</label>
                <br />
                <Calendar
                  value={
                    goalDetail.activityDate
                      ? new Date(goalDetail.activityDate)
                      : null
                  }
                  onChange={(e) => {
                    setGoalDetails((prev) =>
                      prev.map((detail, i) =>
                        i === index
                          ? { ...detail, activityDate: e.value }
                          : detail
                      )
                    );
                  }}
                  disabled={goalDetail.deletedAt !== null}
                  placeholder="Select Date"
                  dateFormat="dd/mm/yy"
                  className="p-mb-2 mb-1 mt-1 full-width"
                />
              </div>
              <div className="col-12">
                <label>Activity Note</label>
                <InputTextarea
                  value={goalDetail.activityNotes}
                  onChange={(e) => {
                    setGoalDetails((prev) =>
                      prev.map((detail, i) =>
                        i === index
                          ? { ...detail, activityNotes: e.target.value }
                          : detail
                      )
                    );
                  }}
                  disabled={goalDetail.deletedAt !== null}
                  placeholder="Write Activity Note"
                  className="p-mb-2 mb-1 mt-1 full-width "
                  rows={3}
                />
              </div>

              <div
                className="col-12 p-d-flex p-jc-center p-ai-center"
                style={{ float: "right" }}
              >
                <Button
                  icon="pi pi-plus"
                  className="p-button-sm p-button-rounded p-button-primary"
                  onClick={addGoalDetailRecord}
                  style={{ marginRight: "5px" }}
                />
                {goalDetail.deletedAt ? (
                  <Button
                    icon="pi pi-eye"
                    className="p-button-sm p-button-rounded p-button-secondary"
                    onClick={() =>
                      restoreGoalDetailRow(goalDetail.goalDetailId)
                    }
                  />
                ) : (
                  <Button
                    icon="pi pi-eye-slash"
                    className="p-button-sm p-button-rounded p-button-secondary"
                    onClick={() => removeGoalDetailRow(goalDetail.goalDetailId)}
                  />
                )}
              </div>
            </>
          ))}
          <div className="col-12 mb-2 mt-2">
            <Button
              label={editMode ? "Update Goal" : "Add Goal"}
              onClick={handleSaveGoal}
              className="btn-primary"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SingleGoal;
