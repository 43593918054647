import React, { useEffect, useState } from "react";
import DataListing from "../Common/DataListing";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import {
  createGoalActivity,
  deleteGoalActivity,
  getAllGoalActivities,
  updateGoalActivity,
} from "../../api/goalActivityApi";
import { toast } from "react-toastify";

const GoalActivitiesList = () => {
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);
  const [goalActivities, setGoalActivities] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [goalActivity, setGoalActivity] = useState({
    name: "",
    description: "",
  });
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    const fetchGoalDomains = async () => {
      setLoading(true);
      try {
        const goalActivities = await getAllGoalActivities();
        setGoalActivities(goalActivities);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchGoalDomains();
  }, [refreshData]);
  const handleEdit = (goalActivity) => {
    setGoalActivity(goalActivity);
    setEditMode(true);
    setShowDialog(true);
  };
  const handleDelete = async (goalDomainId) => {
    setLoading(true);
    const response = await deleteGoalActivity(goalDomainId);
    setLoading(false);
    toast.warning(response, { autoClose: 1000 });
    setRefreshData(!refreshData);
  };
  const responseAlert = (response, autoClose) => {
    if (response.success) {
      toast.success(response.msg, { autoClose: autoClose });
    } else {
      toast.error(response.msg, { autoClose: autoClose });
    }
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);
      let response = {};
      if (editMode) {
        response = await updateGoalActivity(goalActivity.id, goalActivity);
      } else {
        response = await createGoalActivity(goalActivity);
      }
      responseAlert(response, 500);
      setLoading(false);
      setRefreshData(!refreshData);
      setShowDialog(false);
      setGoalActivity({ name: "", description: "" });
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGoalActivity((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleAdd = () => {
    setShowDialog(true);
  };
  const columns = [
    {
      field: "id",
      header: "ID",
    },
    {
      field: "name",
      header: "Name",
    },
    {
      field: "description",
      header: "Description",
    },
  ];
  return (
    <div>
      <DataListing
        title="Goal Activities"
        subject={"Goal Activity"}
        data={goalActivities}
        columns={columns}
        onCreate={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
      <Dialog
        visible={showDialog}
        onHide={() => {
          setShowDialog(false);
          setGoalActivity({
            name: "",
            description: "",
          });
        }}
        style={{ width: "30vw" }}
        header={editMode ? "Edit Goal Activity" : "Add Goal Activity"}
      >
        <div className="form-group">
          <div className="p-fluid">
            <div className="p-field mb-2">
              <label htmlFor="name">Name</label>
              <InputText
                id="name"
                name="name"
                value={goalActivity.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="p-field mb-2">
              <label htmlFor="description">Description</label>
              <InputText
                id="description"
                name="description"
                value={goalActivity.description}
                onChange={handleChange}
                required
              />
            </div>

            <Button
              label="Add"
              //   label={isEdit ? "Update" : "Add"}
              style={{ marginTop: "1rem" }}
              className="btn-primary"
              onClick={handleSubmit}
            />
          </div>
          {loading && (
            <div style={spinnerContainerStyle}>
              <ProgressSpinner />
            </div>
          )}
        </div>
      </Dialog>
    </div>
  );
};
const spinnerContainerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
};
export default GoalActivitiesList;
