import React, { useState, useEffect } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import DynamicDataTable from "../Common/DataListing";
import SingleUser from "./SingleUser";
// import SummaryCard from '../Common/SummaryCards';
import {
  getUsers,
  addUser,
  editUser,
  deleteUser,
  editUserPermissions,
  getUserDirectPermissions,
  fetchUserPermissions,
} from "../../api/userApi";
import { getServices } from "../../api/serviceApi";
import { TabPanel, TabView } from "primereact/tabview";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { getPermissions } from "../../api/permissonApi";
import { hasPermission } from "../../utils/permissions";
import { useAuth } from "../../contexts/AuthContext";

// import './UsersList.css'; // Import CSS file

const UsersList = () => {
  const [usersData, setUsersData] = useState([]); //to be used for DataListing
  const [groupedUsers, setGroupedUsers] = useState({}); // For storing grouped users
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    roleId: "",
    isActive: false,
  }); //to be used for SingleUser (Add/Edit)
  const [userPermissions, setUserPermissions] = useState({});
  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDirectPermissionsModalVisible, setIsDirectPermissionsModalVisible] =
    useState(false);
  const [isTherapistServicesModalVisible, setIsTherapistServicesModalVisible] =
    useState(false);

  const { user } = useAuth();
  useEffect(() => {
    const fetchUserDirectPermissions = async () => {
      try {
        const userPermissions = await getUserDirectPermissions(selectedUserId);
        setUserPermissions((prevState) => ({
          ...prevState,
          permissions: userPermissions,
        }));
      } catch (error) {
        console.error("Error fetching role permissions", error);
      }
    };
    isDirectPermissionsModalVisible && fetchUserDirectPermissions();
    // fetchUserDirectPermissions();
  }, [selectedUserId, isDirectPermissionsModalVisible]);
  useEffect(() => {
    const getPermissions = async () => {
      setLoading(true);
      try {
        const userPermissions = await fetchUserPermissions(user.id);
        // console.log("userPermissions", userPermissions);
        setPermissions(userPermissions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching permissions:", error);
        setLoading(false);
      }
    };
    getPermissions();
  }, []);
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const data = await getPermissions();
        setPermissionGroups(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching permissions", error);
      }
    };

    fetchPermissions();
  }, []);

  const renderIsActiveSwitch = (rowData) => {
    return (
      <InputSwitch
        checked={rowData.isActive}
        onChange={(e) => handleInlineEdit(rowData.id, { isActive: e.value })}
      />
    );
  };
  const renderEmailVerifiedSwitch = (rowData) => {
    return (
      <InputSwitch
        checked={rowData.emailVerified}
        onChange={(e) =>
          handleInlineEdit(rowData.id, { emailVerified: e.value })
        }
      />
    );
  };

  const renderUserRole = (rowData) => {
    return <span>{rowData.role.name}</span>;
  };

  const [columns, setColumns] = useState([
    { field: "name", header: "Name" },
    { field: "email", header: "Email" },
    { field: "isActive", header: "Is Active", body: renderIsActiveSwitch },
    {
      field: "emailVerified",
      header: "Email Verified?",
      body: renderEmailVerifiedSwitch,
    },
    { header: "Role", body: renderUserRole, nofilter: true },
  ]);
  const filteredRoleColumns = [
    { field: "name", header: "Name" },
    { field: "email", header: "Email" },
    { field: "isActive", header: "Is Active", body: renderIsActiveSwitch },
    {
      field: "emailVerified",
      header: "Email Verified?",
      body: renderEmailVerifiedSwitch,
    },
  ];
  useEffect(() => {
    fetchUsersData();
    fetchServices();
  }, []);

  const fetchUsersData = async () => {
    setLoading(true);
    try {
      const data = await getUsers();
      // Group users by role name
      const grouped = data.reduce((acc, user) => {
        const roleName = user.role?.name || "No Role"; // Handle case where role or name is undefined
        if (!acc[roleName]) {
          acc[roleName] = [];
        }
        acc[roleName].push(user);
        return acc;
      }, {});

      setUsersData(data); // Store the original users data
      setGroupedUsers(grouped); // Group users by role name
      // console.log(groupedUsers);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await getServices();
      setServices(response);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching services", error);
      setLoading(false);
    }
  };

  // const handleCreate = async (userData) => {
  //     await addUser(userData);
  //     fetchUsersData();
  // };

  const responseAlert = (response, autoClose) => {
    if (response.success) {
      toast.success(response.msg, { autoClose: autoClose });
    } else {
      toast.error(response.msg, { autoClose: autoClose });
    }
  };

  const handleInlineEdit = async (userId, userData) => {
    setLoading(true);
    try {
      await editUser(userId, userData);
      await fetchUsersData();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const handleCreate = () => {
    setIsEditing(false);
    setUserData({
      name: "",
      email: "",
      roleId: "",
      password: "",
      isActive: true,
    });
    setIsModalVisible(true);
  };

  const handleEdit = (rowData) => {
    setIsEditing(true);
    console.log("user rowData", rowData);
    setUserData(rowData); // Populate form with selected row data
    setIsModalVisible(true);
    setIsChangingPassword(false);
  };
  const handleSubmit = async () => {
    setLoading(true);
    let response = {};
    try {
      if (isEditing) {
        response = await editUser(userData.id, userData); // Update the user
      } else {
        response = await addUser(userData); // Create a new user
      }
      await fetchUsersData(); // Refresh the data in the table
      setIsModalVisible(false);
      responseAlert(response, 300);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Ensure loading is reset even if an error occurs
    }
  };

  const handleDelete = async (userId) => {
    await deleteUser(userId);
    fetchUsersData();
  };

  const handleServiceChange = (newServices) => {
    console.log("newServices", newServices);
    setSelectedServices(newServices);
    console.log("onchange services", selectedServices);
    setUserData((prevData) => ({ ...prevData, services: newServices }));
  };

  const handleTherapistServices = async (rowData) => {
    setUserData(rowData);
    setSelectedServices(rowData.assignedServices);
    setIsTherapistServicesModalVisible(true);
  };

  const updateTherapistServices = async () => {
    setLoading(true);
    let response = {};
    try {
      response = await editUser(userData.id, userData); // Update the user
      responseAlert(response, 300);
      setIsTherapistServicesModalVisible(false);
      await fetchUsersData();
    } catch (error) {
      console.error(error);
      responseAlert(response, 300);
    } finally {
      setLoading(false); // Ensure loading is reset even if an error occurs
    }
  };
  const handleDirectPermissions = async (rowData) => {
    setUserData(rowData);
    setSelectedUserId(rowData.id);
    setIsDirectPermissionsModalVisible(true);
  };
  const handleDirectPermissionsSubmit = async () => {
    setLoading(true);
    let response = {};
    // console.log(userPermissions);

    try {
      response = await editUserPermissions(userData.id, userPermissions); // Update the user
      setIsDirectPermissionsModalVisible(false);
      response.success && toast.success(response.message, { autoClose: 500 });
      await fetchUsersData();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Ensure loading is reset even if an error occurs
    }
  };
  const extraActions = [
    hasPermission(permissions, "User Permission", "read") && {
      label: "Add/Update Direct Permissions",
      className: "btn-outline-primary",
      handler: handleDirectPermissions,
    },
    {
      // icon: 'pi pi-user-edit',
      label: "Therapist Services",
      className: "btn-outline-primary",
      handler: handleTherapistServices,
      condition: (rowData) => rowData.roleId === 4,
    },
  ].filter(Boolean);
  const handlePermissionChange = (groupName, selectedPermissions) => {
    // console.log(groupName, selectedPermissions);

    setUserPermissions((prevState) => ({
      ...prevState,
      permissions: {
        ...prevState.permissions,
        [groupName]: selectedPermissions,
      },
    }));
    // setUserPermissions((prevState) => ({
    //   ...prevState,
    //   [groupName]: selectedPermissions,
    // }));
    // console.log(userPermissions);

    // console.log("updated role 2", role);
  };
  return (
    <div>
      {/* <div className="summary-cards-container">
                <SummaryCard title="Total Users" value={usersData.length} />
                <SummaryCard title="Admins" value={usersData.filter(user => user.role === 'Admin').length} />
                <SummaryCard title="Therapists" value={usersData.filter(user => user.role === 'Therapist').length} />
                <SummaryCard title="Parents" value={usersData.filter(user => user.role === 'Parent').length} />
            </div> */}
      <TabView>
        <TabPanel header="All Users">
          <DynamicDataTable
            data={usersData}
            subject={"User"}
            columns={columns}
            onCreate={handleCreate}
            onEdit={handleEdit}
            onDelete={handleDelete}
            extraActions={extraActions}
          />
        </TabPanel>
        {Object.entries(groupedUsers).map(([roleName, users]) => (
          <TabPanel header={roleName} key={roleName}>
            <DynamicDataTable
              data={users}
              subject={"User"}
              columns={filteredRoleColumns}
              onCreate={handleCreate}
              onEdit={handleEdit}
              onDelete={handleDelete}
              extraActions={extraActions}
            />
          </TabPanel>
        ))}
      </TabView>
      <Dialog
        header={isEditing ? "Edit User" : "Add New User"}
        visible={isModalVisible}
        style={{ width: "30vw" }}
        className="form-box"
        onHide={() => setIsModalVisible(false)}
      >
        <SingleUser
          isChangingPassword={isChangingPassword}
          setIsChangingPassword={setIsChangingPassword}
          user={userData}
          setUser={setUserData}
          onSubmit={handleSubmit}
          isEdit={isEditing}
          loading={loading}
          setLoading={setLoading}
        />
      </Dialog>
      <Dialog
        header="Therapist Services"
        visible={isTherapistServicesModalVisible}
        style={{ width: "30vw" }}
        onHide={() => setIsTherapistServicesModalVisible(false)}
      >
        <div className="form-group">
          <div className="p-field">
            <MultiSelect
              id="therapistServices"
              value={selectedServices || []}
              options={services.map((service) => ({
                label: `${service.name}`,
                value: service,
              }))}
              onChange={(e) => handleServiceChange(e.value)}
              optionLabel="label"
              optionValue="value"
              display="chip"
              style={{ width: "100%" }}
            />
          </div>
          <div className="mt-2">
            <Button
              label="Submit"
              className="full-width btn-primary"
              icon="pi pi-check"
              onClick={() => updateTherapistServices()}
            />
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Direct User Permissions"
        visible={isDirectPermissionsModalVisible}
        style={{ width: "30vw" }}
        onHide={() => setIsDirectPermissionsModalVisible(false)}
      >
        <div className="form-group">
          <div className="p-fluid">
            <div className="p-field">
              <label htmlFor="name">User Name</label>
              <InputText id="name" name="name" value={userData.name} disabled />
            </div>
            <label className="mb-2">User Permissions</label>
            <Accordion multiple>
              {Object.entries(permissionGroups).map(
                ([moduleName, modulePermissions]) => (
                  <AccordionTab key={moduleName} header={moduleName}>
                    <div className="p-field">
                      <MultiSelect
                        id={moduleName}
                        value={userPermissions.permissions?.[moduleName] || []}
                        options={modulePermissions.map((perm) => ({
                          label: `${perm.action} ${perm.subject}`,
                          value: perm.id,
                        }))}
                        onChange={(e) =>
                          handlePermissionChange(moduleName, e.value)
                        }
                        optionLabel="label"
                        optionValue="value"
                        // placeholder={`Select ${moduleName} Permissions`}
                        display="chip"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </AccordionTab>
                )
              )}
            </Accordion>
            <Button
              label="Change Permissions"
              className="btn-primary mt-2"
              onClick={handleDirectPermissionsSubmit}
            />
          </div>
        </div>
      </Dialog>
      {loading && (
        <div style={spinnerContainerStyle}>
          <ProgressSpinner />
        </div>
      )}
    </div>
  );
};
const spinnerContainerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 1000,
};
export default UsersList;
